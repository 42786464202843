module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Senior Saude Movel","short_name":"Senior Saude Movel","description":"A aplicação web da Sênior Saúde Movel é utilizada por profissionais de saúde e cuidadores para monitorizar as atividades físicas realizadas, registos de sono, diagnósticos, medições e muito mais.","start_url":"/","background_color":"#FAFAFA","theme_color":"#50BB98","display":"minimal-ui","icon":"static/icons/ssm_256x256.png","icons":[{"src":"/icons/ssm_16x16.png","sizes":"16x16","type":"image/png","purpose":"any maskable"},{"src":"/icons/ssm_24x24.png","sizes":"24x24","type":"image/png","purpose":"any maskable"},{"src":"/icons/ssm_32x32.png","sizes":"32x32","type":"image/png","purpose":"any maskable"},{"src":"/icons/ssm_64x64.png","sizes":"64x64","type":"image/png","purpose":"any maskable"},{"src":"/icons/ssm_128x128.png","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"/icons/ssm_256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"/icons/ssm_512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c37bc04430e833a6ded9d302d95d9951"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#50BB98"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-98273729","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
